main {
    display: flex;
    flex-direction: column;
}

.game-sec1 {
    width: 98%;
    align-self: center;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;

    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
    border-radius: 5px;
    background: var(--Dark-Dark-2, #272A2F);
    padding: 10px;

}

.game-sec1>button {
    width: fit-content;
    border: none;
    background-color: transparent;
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
}

.game-sec1>button:hover,
.game-sec1 .selected {
    border-radius: 5px;
    background: var(--Dark-Dark-3, #1E1E1E);
}

.game-sec2 {
    display: flex;
    width: 100%;
    /* height: fit-content; */
    padding: 2%;
    justify-content: center;
    margin-top: 20px;
    /* border: 1px solid white; */
    gap: 5px;
    flex-wrap: wrap;
    max-height: 80%;
    overflow: scroll;
    align-items: stretch;
}


.game-sec2 .game {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    flex-direction: column;
    gap: 5px;
    width: 70px;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
    border-radius: 5px;
    background: var(--Dark-Dark-2, #272A2F);
    align-self: stretch;
    
}


.game-sec2 > a{
    text-decoration: none;

}
.game-sec2 .game>img {
    width: 50px;
    height: auto;
    border-radius: 5px;
    
    justify-content: center;
    align-items: center;
}

.game-sec2 .game>p {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
}