
.main-body {
    width: 100vw;
    max-width: 450px;
    height: 100vh;
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    overflow-y: scroll;
    overflow-x: hidden;
}

.main-body > main{
    width: 100%;
    height: fit-content;
    max-width: 450px;
}

.main-body > nav {
    width: 100%;
    max-width: 450px;
    position: fixed;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 5;
    bottom: 0px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-around;
    background: var(--Dark-Dark-2, #272A2F);
}

.main-body > nav > ul {
    width: 100%;
    max-width: 450px;
    display: flex;
    justify-content: space-around;
    gap: 12px;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    align-self: center;
    justify-self: center;
    transform: translateX(-20px);


}

.main-body>nav>ul> a {
    text-decoration: none;
    color: inherit;
}

.main-body>nav>ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    /* border: 1px solid white; */
}











.main-body .box1 {
    margin-top: 40px;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
}



.main-body>nav>ul {
    cursor: pointer;
}

.main-body>nav>ul li:hover,
.main-body>nav>ul .selected {
    border-radius: 8px;
    background: var(--Dark-Dark-3, #1E1E1E);
    cursor: pointer;
}

.main-body>nav>ul .selected>.page-name {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
}

.main-body>nav>ul li>.page-name {
    color: var(--Dark-Dark-1, #696969);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
}




main .box2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;

}



main .box2 .mission-title {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 20px 30px;
    flex-direction: column;

}

main .box2 .mission-title .t1 {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.6px;
    gap: 15px;
}

main .box2 .mission-title .t2 {
    color: var(--Light-Light-3, #A4A4A4);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}


main .box2 .daily-task {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    gap: 15px;
    padding: 20px 15px;
}

main .box2 .daily-task .title {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;

}

main .box2 .daily-task .task {
    align-self: center;
    display: flex;
    width: 98%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 12px;
    background: #33353A;
}


main .box2 .daily-task .task>img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

main .box2 .daily-task .task .data {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

main .box2 .daily-task .task .data .coin {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}