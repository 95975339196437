.payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
}



.paymentSectionHeader {
    color: #FFF;
    text-align: center;
    font-family: Ubuntu, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.64px;
}


.selectTransactionDataType {
    display: flex;
    flex-wrap: nowrap;
    width: 80%;
    gap: 15px;

    display: flex;
    padding: 10px 25px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    background: var(--Dark-Dark-2, #272A2F);

    justify-content: space-around;
    align-items: center;
    padding: 16px 25px;
    align-self: center;

}


.selectTransactionDataType>button {
    border: none;
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
    background-color: transparent;
    align-self: center;
    cursor: pointer;
}

.transactionSelected {

    background: var(--Dark-Dark-3, #1E1E1E);
    border: 1px solid white;
}






.paymentForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 25px;
    margin-top: 50px;
}

.payAmount {

    width: 80%;
    display: flex;
    max-width: 362px;
    padding: 20px;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: var(--Dark-Dark-3, #1E1E1E);
    border: none;
    outline: none;

    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
}

.paymentHeader {
    color: #FFF;
    font-family: Inter, Ubuntu, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.paymentFormSubmitButton {
    display: flex;
    width: 362px;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--Primary-Primary-5, #4E5ADD);
    border: none;

    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    cursor: pointer;

    width: 80%;
}

.paymentHistory {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    align-self: center;

    justify-content: center;
    align-items: center;
    padding: 5px 20px;

    border-radius: 10px;
    background: var(--Dark-Dark-2, #272A2F);
}

.historyRow {
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;

}

.depositionType {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 80%;
    align-self: center;

    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;

    border-radius: 10px;
    background: #33353A;

}


.totalDeposit {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    justify-self: flex-start;
    align-self: flex-start;
    gap: 10px;
}

.totalDeposit>p {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;

    padding: 0px;
    margin: 0px;
}

.totalDeposit>div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    padding: 0px;
    margin: 0px;
}

.totalDeposit>div>p {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;

    padding: 0px;
    margin: 0px;
}




















.k {}