.main-body main .userData {
    display: flex;
    gap: 20px;
}


.main-body main .userData .user-data {
    display: flex;
    flex-direction: column;
    gap: 14px;
}


.main-body main .userData .user-data>p {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
}


.main-body main .box1 .wallet {
    display: flex;
    width: 104px;
    padding: 5px 23px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid var(--Light-Light-1, #F6F6F6);
    position: relative;
}


.main-body main .box1 .wallet .plus {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #000;
    position: absolute;
    transform: translate(180%, -90%);
}

.main-body main .box1 .wallet button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}



.box2AreaMask{
    display: flex;
    position: relative;
    flex-direction: column;
    height: fit-content;
}

.main-body main .box2AreaMask .box2-mask {
    position: absolute;
    top: 30px;
    border: 1px solid green;
    width: 100%;
    box-sizing: border-box;
    height: 118px;
    flex-shrink: 0;
    border-radius: 0px 0px 30px 30px;
    background: #4E5ADD;
    filter: blur(30px);
    /* background-color:transparent; */
    z-index: 1;
}

.main-body main .box2 {
    position: relative;
    height: 500px;
    margin-top: 40px;
    overflow: scroll;
    background: black;
    z-index: 2;
    border-radius: 30px 30px 0px 0px;
    border-top: 2px solid var(--Primary-Primary-5, #4E5ADD);
    background: #1C1F24;
}



.main-body main .box2 .sec1 .balance {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.main-body main .box2 .sec1 .balance .coins {
    display: flex;
    gap: 5px;

}

.main-body main .box2 .sec1 .balance .t1 {
    color: var(--Light-Light-3, #A4A4A4);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.6px;
}

.main-body main .box2 .sec1 .balance .coins .t2 {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
}


.main-body main .box2 .league {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}




.main-body main .box2 .league .levels {
    display: flex;
    width: 146px;
    height: fit-content;
    padding: 5px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
    /* border: 1px solid white; */
}


.main-body main .box2 .league .levels .league-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.main-body main .box2 .league .levels .league-info .league-info-charter {
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.main-body main .box2 .league .levels .league-info .league-info-charter .t1 {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

.main-body main .box2 .league .levels .league-info .league-info-charter .t2 {
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

.main-body main .box2 .league .levels .league-info .league-info-charter .t2 span {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

.main-body main .box2 .league .levels .league-info .progress {
    height: 9px;
    align-self: stretch;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.10);
}

.main-body main .box2 .league .crest {
    display: flex;
    /* height: 32px; */
    padding: 14px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
}


