.sec1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}


.sec1 .intro {

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 25px 15px;
    margin-top: 25px;
    flex-direction: column;
    gap: 20px;
}



.sec1 .intro .t1 {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.56px;
}

.sec1 .intro .t2 {
    color: var(--Light-Light-2, #CDCDCD);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
}

.sec1 .bonus-types {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.sec1 .bonus-types .bonus {
    width: 90%;


    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    min-height: 100px;
    border-radius: 12px;
    background: #33353A;
    padding: 20px 15px;

}


.sec1 .bonus-types .bonus .data {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sec1 .bonus-types .bonus .data .coin {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.sec1 .bonus-types .bonus>button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.sec1 .bonus-types .bonus:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sec1 .bonus-types .bonus .invite-button {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 10px;
    background: var(--Primary-Primary-5, #4E5ADD);

    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
}


.sec1 .invite-link {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.sec1 .invite-link .title {
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;

}

.sec1 .invite-link .invite-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.sec1 .invite-link .invite-button>button{cursor: pointer;}
.sec1 .invite-link .invite-button>button:nth-child(1) {
    width: 100px;

    height: 36px;
    padding: 10px;

    width: 80%;
    border-radius: 5px;
    background: var(--Primary-Primary-5, #4E5ADD);
    border: none;
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.7px;

}

.sec1 .invite-link .invite-button>button:nth-child(2) {
    display: flex;
    width: 69px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--Primary-Primary-5, #4E5ADD);
    background-color: transparent;
}